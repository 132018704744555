<template>
  <div class="wrapper">
    <Header :headerData="{ name: undefined }" />
    <CloudAndAccess :merchantListData="merchantListData" :total="total" @emitChange="handleChange" />
    <Footer />
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue'
import Header from '../../components/Header'
import CloudAndAccess from '../../components/CloudAndAccess'
import Footer from '../../components/Footer'
import { post_merchant_list, IMAGE_URL } from '../../api'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    Header,
    Footer,
    CloudAndAccess,
  },
  data() {
    return {
      merchantListData: [],
      total: 0,
    }
  },
  mounted() {
    this.getMerchantList()
  },
  methods: {
    async getMerchantList(page = 1) {
      const {
        data: { code, data },
      } = await post_merchant_list({ limit: 10, page, is_pavilion: '' })
      if (code == 1) {
        this.total = data.total
        this.merchantListData = data.data.map((item) => ({
          ...item,
          image: IMAGE_URL + item.image,
          images: IMAGE_URL + item.images,
          logo: IMAGE_URL + item.logo,
          yyzz_images: IMAGE_URL + item.yyzz_images,
        }))
      } else {
        message.error('数据获取失败, 请重试')
      }
    },
    handleChange(index) {
      window.scrollTo(0, 500)
      this.getMerchantList(index)
    },
  },
})
</script>

<style lang="less" scoped>
.wrapper {
  width: 100%;
  background: #fafafa;
}
</style>
